<template>
  <b-modal
    id="cms-create-row"
    title="New Column"
    no-close-on-backdrop
    size="lg"
    @ok="onOk"
  >
    <ValidationObserver ref="cmsForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          v-slot="{ errors }"
          name="image_src"
          :rules="{
            required: false,
          }"
        >
          <b-form-group label="Column image">
            <CmsFileSelect
              :value="createRowForm.imageSrc"
              :state="errors[0] ? false : null"
              @update="(value) => (createRowForm.imageSrc = value || '')"
            />
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="title_type"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Column type">
            <b-form-select
              id="title_type"
              v-model="createRowForm.cardType"
              :state="errors[0] ? false : null"
            >
              <b-form-select-option
                v-for="(item, index) in cmsEnumTypes.cards"
                :key="index"
                :value="item"
                >{{ item }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </ValidationProvider>
        <b-form-group label="Youtube Iframe">
          <b-form-input v-model="createRowForm.youtubeIframe"></b-form-input>
        </b-form-group>
        <ValidationProvider
          v-slot="{ errors }"
          name="image_src"
          :rules="{
            required: false,
          }"
        >
          <b-form-group label="Content">
            <vue-editor
              v-model="createRowForm.content"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </ValidationProvider>
        <b-row>
          <b-col cols="3">
            <b-form-group label="Lg">
              <b-form-input
                v-model.number="createRowForm.lg"
                min="1"
                max="12"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Md">
              <b-form-input
                v-model.number="createRowForm.md"
                min="1"
                max="12"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Sm">
              <b-form-input
                v-model.number="createRowForm.sm"
                min="1"
                max="12"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Cols">
              <b-form-input
                v-model.number="createRowForm.cols"
                min="1"
                max="12"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <ValidationProvider
          v-slot="{ errors }"
          name="position"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Position">
            <b-form-input
              id="position"
              v-model="createRowForm.position"
              :state="errors[0] ? false : null"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    CmsFileSelect: () => import('../file-select.vue'),
    VueEditor,
  },
  props: {
    layoutId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      createRowForm: {
        position: 0,
        imageSrc: '',
        lg: null,
        md: null,
        sm: null,
        cols: null,
        content: '',
        youtubeIframe: '',
        cardType: this.cmsEnumTypes?.cards?.[0],
      },
    }
  },
  computed: {
    ...mapState({
      cmsEnumTypes: (state) => state.cms.cmsEnumTypes,
    }),
    ...mapGetters(['siteId']),
  },
  methods: {
    ...mapActions(['createCmsRow']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-create-row'))
      }
    },
    async onSubmit(cb) {
      // This is not actually row it a column = =
      await this.createCmsRow({
        siteId: this.siteId,
        layoutId: this.layoutId,
        data: this.createRowForm,
      })

      cb()
    },
  },
}
</script>
